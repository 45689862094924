import { useState, useEffect } from 'react';
import RealtimeTable from '../components/RealtimeTable';
import MainLayout from '../layouts/MainLayout';

const Realtime = () => {
    // Initialize the state with an empty array
    const [data, setData] = useState([]);

    // Function to fetch data from the API and update the state
    const fetchData = async () => {
        try {
            const response = await fetch('https://iot.aisack.com/dataset/realtime/sahatanachon/nongree');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            // Transform the API response into an array with status based on timestamp
            const newData = Object.keys(jsonData).map((key) => {
                const timestamp = new Date(jsonData[key].timestamp).getTime();
                const currentTime = new Date().getTime();
                const timeDiffInSeconds = (currentTime - timestamp) / 1000;

                return {
                    name: key,
                    value: jsonData[key].value,
                    unit: jsonData[key].unit,
                    timestamp: new Date(jsonData[key].timestamp).toLocaleString(),
                    status: timeDiffInSeconds <= 120 ? 'green' : 'red', // Check if timestamp is within 2 minutes
                };
            });

            setData(newData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch data initially when the component mounts
    useEffect(() => {
        fetchData();

        // Set up an interval to fetch data every 30 seconds
        const intervalId = setInterval(() => {
            fetchData();
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []); // Empty dependency array to run the effect only once

    return (
        <MainLayout>
            <div className='px-5 py-2'>
                <div className='px-3 py-2 font-bold text-xl'>Realtime Data</div>
                <RealtimeTable data={data}></RealtimeTable>
            </div>
        </MainLayout>
    );
};

export default Realtime;
