import '../styles/main.css';

function AuthLayout({ children }) {
  return (
    <div className="flex justify-center py-5 md:py-20 px-5 h-screen w-full authLayout">
        <div className="w-full md:w-6/12 lg:w-5/12 xl:w-4/12  bg-white p-10 rounded-lg">
        <img
            src="/logo.png" // Replace with the actual path to your logo
            alt="Logo"
            className="w-8/12 mx-auto mb-4" // Adjust styling as needed
          />
        <div><main>{children}</main></div>
        </div>
    </div>
  );
}

export default AuthLayout;
