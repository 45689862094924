import React, { useState } from 'react';
import HistoricalChart from '../components/HistoricalChart';
import HistoricalTable from '../components/HistoricalTable';
import MainLayout from '../layouts/MainLayout';

const Historical = () => {

  const initialTagList = [
    { thingId: 10, name: 'CH_1 Flow meter 01', checked: false },
    { thingId: 9, name: 'CH_2 Tot Flow meter 01', checked: false },
    { thingId: 8, name: 'CH_3 Flow meter 02', checked: false },
    { thingId: 7, name: 'CH_4 Tot Flow meter 02', checked: false },
    { thingId: 6, name: 'CH_5 pH', checked: false },
    { thingId: 5, name: 'CH_6 Conductivity', checked: false },
    { thingId: 4, name: 'CH_7 Salinity', checked: false },
    { thingId: 3, name: 'CH_8 Turbidity', checked: false },
    { thingId: 2, name: 'CH_9 Chlorine', checked: false },
    { thingId: 1, name: 'CH_10 Water temp', checked: false },
  ];

  const [tagList, setTagList] = useState(initialTagList);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleTagChange = (tagId) => {
    const updatedTagList = tagList.map((tag) => {
      if (tag.thingId === tagId) {
        // Toggle the 'checked' property
        return { ...tag, checked: !tag.checked };
      }
      return tag;
    });

    setTagList(updatedTagList);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearch = () => {
    const st = Date.parse(startDate);
    const et = Date.parse(endDate);

    var chData = [];

    const randomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const thingIds = tagList
      .filter((tag) => tag.checked)
      .map((tag) => tag.thingId);

      //console.log("Get data");

      for(var i=0;i<thingIds.length;i++)
      {
        chData[i] = {
          label: (initialTagList.filter(tag => tag.thingId === thingIds[i]))[0].name,
          data: [],
          fill: false,
          borderColor: randomColor(),
          tension: 0.1,
        }
      }

      //console.log(chData);

    const apiUrl = `https://iot.aisack.com/dataset/historical/sahatanachon/nongree?st=${st}&et=${et}&thingIds=${JSON.stringify(
      thingIds
    )}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API, you can update your state or perform other actions here
        //console.log(data);

        var processData = [];

        var tagName = "";

        var udTime = [];

        var timeLocal;

        for(var i=0;i<data.length;i++)
        {
          timeLocal = (data[i].groupTime);

          processData[i] = {};
          processData[i]['timestamp'] = timeLocal;

          udTime[i] = timeLocal;

          for(var j=0;j<data[i].values.length;j++)
          {
            tagName = (initialTagList.filter(tag => tag.thingId === data[i].values[j].thingId_ref))[0].name;

            processData[i][tagName] = data[i].values[j].value;

            // Push value to chData
            const chDataIndex = thingIds.indexOf(data[i].values[j].thingId_ref);
            chData[chDataIndex].data.push(data[i].values[j].value);
          }

        }

        //console.log(processData);

        setData(processData);
        setUpdateTimes(udTime);
        setChartData(chData);
      })
      .catch((error) => {
        // Handle errors here
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const [data, setData] = useState([]);

  // Sample data to pass to DashboardChart
  const [chartData, setChartData] = useState([]);

const [updateTimes, setUpdateTimes] = useState([]);

const exportToCSV = () => {
  // Generate a timestamp for the file name
  const timestamp = new Date().toLocaleString().replace(/[-:]/g, '').replace(/\s/g, '_');

  // Create a CSV string from your data
  const csvContent = "data:text/csv;charset=utf-8," +
    "Timestamp," + tagList
      .filter((tag) => tag.checked)
      .map((tag) => tag.name)
      .join(',') + '\n' +
    data.map((row) =>
      [row.timestamp].concat(
        tagList
          .filter((tag) => tag.checked)
          .map((tag) => row[tag.name])
      ).join(',')
    )
    .join('\n');

  // Create a data URI for downloading
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `historical_data_${timestamp}.csv`); // Append timestamp to the file name
  document.body.appendChild(link);
  link.click();
};
  return (
    <MainLayout>
      <div className="px-5 py-2">
        <div className="flex px-3 py-2 justify-between">
          <div className="font-bold text-xl">Historical Data</div>
          <div className="flex">
            <div className="flex items-center m-2">
              <label htmlFor="startDate" className="mr-2">
                Start Date & Time:
              </label>
              <input
                type="datetime-local"
                id="startDate"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="flex items-center m-2">
              <label htmlFor="endDate" className="mr-2">
                End Date & Time:
              </label>
              <input
                type="datetime-local"
                id="endDate"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <button
              className="bg-blue-500 text-white mx-5 px-4 rounded"
              onClick={handleSearch}
            >
              Search
            </button>
            
            {/* Add Export to XLS Button */}
            <button
              className="bg-green-500 text-white px-4 rounded ml-2"
              onClick={exportToCSV}
            >
              Export
            </button>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="bg-slate-100 rounded-xl p-2 w-3/12 tagList">
            <div className="font-bold text-base p-1">Tag List</div>

            <ul>
              {tagList.map((tag) => (
                <li key={tag.thingId} className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    id={`tag-${tag.thingId}`}
                    //checked={tag.checked}
                    onChange={() => handleTagChange(tag.thingId)}
                    className="mx-3"
                  />
                  <label htmlFor={`tag-${tag.id}`}>{tag.name}</label>
                </li>
              ))}
            </ul>

          </div>
          <div className="pl-5 pr-3 w-full">
            <div className="bg-slate-100 w-full rounded-xl chartHs">
              <HistoricalChart chartData={chartData} updateTimes={updateTimes}></HistoricalChart>
            </div>
            <HistoricalTable data={data}></HistoricalTable>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Historical;
