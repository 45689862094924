export default function LogoutConfirmation({ onConfirm, onCancel }) {
  const handleLogout = () => {
    // Clear cookies and local storage here (replace with your actual logic)
    // Example: document.cookie = 'cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // Example: localStorage.removeItem('localStorageItemName');
    onConfirm(); // Close the confirmation popup and log the user out
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">Logout Confirmation</h2>
        <p>Are you sure you want to logout?</p>
        <div className="mt-4 flex justify-between">
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-4 py-2 rounded-xl w-5/12 hover:bg-red-600"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-xl w-5/12 hover:bg-gray-400"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
