export default function SiteBarItem(props) {

    // Define a default background color
    const defaultBgColor = 'bg-white';

    // Combine the default background color with the provided bgColor prop
    const backgroundClass = `${defaultBgColor} ${props.bgColor || ''}`;

    return (

        <div className={`w-full rounded-xl flex ${backgroundClass}`}>

            <div className='flex justify-center'>
                <img
                    src={props.img} // Replace with the actual path to your logo
                    alt="Logo"
                    className="site-img ml-5 my-3 " // Adjust styling as needed
                />
            </div>

            <div className='font-bold text-base my-4 mx-5'>{props.name}</div>

        </div>
    );
}