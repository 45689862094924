export default function AlarmTable({ data }) {
    return (
      <div className='overflow-x-auto mt-3' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <table className='w-full'>
          <thead className='rounded-full bg-slate-200' style={{ position: 'sticky', top: '0' }}>
            <tr>
              <th className='text-center py-2 border'>Name</th>{/* Add border class here */}
              <th className='text-center py-2 border'>Tag</th>{/* Add border class here */}
              <th className='text-center py-2 border'>Description</th>{/* Add border class here */}
              <th className='text-center py-2 border'>Value</th>{/* Add border class here */}
              <th className='text-center py-2 border'>Unit</th>{/* Add border class here */}
              <th className='text-center py-2 border'>Timestamp</th>{/* Add border class here */}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className='border-b border-gray-300'>
                <td className='text-center border'>{item.name}</td>{/* Add border class here */}
                <td className='text-center border'>{item.tag}</td>{/* Add border class here */}
                <td className='text-center border'>{item.description}</td>{/* Add border class here */}
                <td className='text-center border'>{item.value}</td>{/* Add border class here */}
                <td className='text-center border'>{item.unit}</td>{/* Add border class here */}
                <td className='text-center border'>{item.timestamp}</td>{/* Add border class here */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  