

//const IAM_BASE_URL = 'https://iam.aisack.com/core';

//const IOT_BASE_URL = 'https://iot.aisack.com/core';

export const login = async (email, password) => {
  const data = new URLSearchParams();
  data.append('email', email);
  data.append('password', password);

  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: data,
  };

  try {
    const response = await fetch("https://iot.aisack.com/dataset/session/sahatanachon/nongree", config);

    if (!response.ok) {
      // Handle non-OK response status (e.g., 400 Bad Request)
      throw new Error('Login failed');
    }

    const responseData = await response.json();
    // Handle the response data, e.g., store tokens or redirect to the dashboard
    return responseData;
  } catch (error) {
    // Handle any errors, such as displaying an error message
    throw error;
  }
};
