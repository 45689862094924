export default function DashboardTable({ data }) {
    return (
      <div className='overflow-x-auto pt-3' style={{ maxHeight: '100%', overflowY: 'auto' }}>
        <table className='w-full'>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className='border-b border-gray-300 my-2'>
                <td className='px-3 border font-bold'>{item.name}</td>{/* Add border class here */}
                <td className='px-3 text-center border font-bold'>{item.value}</td>{/* Add border class here */}
                <td className='px-3 text-center border font-bold'>{item.unit}</td>{/* Add border class here */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  