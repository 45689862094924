import React, { useState } from 'react';

export default function SiteMenu(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState(props.sites[0]); // Set the initial selected site

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectSite = (site) => {
    setSelectedSite(site);
    setIsOpen(false); // Close the dropdown when a site is selected
  };

  return (
    <div className={`relative flex ${props.className || ''}`}>
      <div
        className='flex justify-between cursor-pointer bg-slate-100 w-full rounded-xl'
        onClick={toggleDropdown}
      >
        <img
          src='/site.png' // Replace with the actual path to your logo
          alt="Logo"
          className="site-img ml-3 my-3 " // Adjust styling as needed
        />

        <div className='font-bold text-base my-4 mx-2'>{selectedSite}</div>

      <div className='flex items-center justify-between'>

        {/* Down Arrow Icon */}
        <div className={`h-4 w-4 mr-4 mb-2`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={`w-6 h-6 ${isOpen ? 'transform rotate-180' : ''}`}>
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>

        </div>
      </div>

      </div>

      {isOpen && (
        <div className="absolute top-10 right-0 mt-1 p-2 bg-white border border-gray-300 rounded shadow-lg z-10 p-3">
          {/* Dropdown content here */}
          <ul>
            {props.sites.map((site, index) => (
              <li key={index} onClick={() => selectSite(site)} className='py-3 cursor-pointer'>
                {site}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
