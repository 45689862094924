import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { isTokenValid } from "./services/auth"; // Import the token validation function

import Login from "./pages/Login";
import Password from "./pages/Password";
import Dashboard from "./pages/Dashboard";
import Realtime from "./pages/Realtime";
import Historical from "./pages/Historical";
import Alarm from "./pages/Alarm";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/password" element={<Password />} />
        {/* Add a route guard to restrict access to these pages */}
        <Route
          path="/dashboard"
          element={isTokenValid() ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/realtime"
          element={isTokenValid() ? <Realtime /> : <Navigate to="/login" />}
        />
        <Route
          path="/historical"
          element={isTokenValid() ? <Historical /> : <Navigate to="/login" />}
        />
        <Route
          path="/alarm"
          element={isTokenValid() ? <Alarm /> : <Navigate to="/login" />}
        />
        {/* Default route when no matches are found */}
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
}
