import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DashboardChart = ({ chartData, updateTimes }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Create a chart
    const ctx = chartRef.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: updateTimes,
        datasets: chartData, // Use the chartData prop to populate datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Hour',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Value',
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [chartData]);

  return <canvas ref={chartRef}></canvas>;
};

export default DashboardChart;
