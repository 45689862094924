import { useState, useEffect } from 'react';
import DashboardChart from '../components/DashboardChart';
import DashboardTable from '../components/DashboardTable';
import MainLayout from '../layouts/MainLayout';

const Dashboard = () => {

        // Initialize the state with an empty array
        const [data, setData] = useState([]);

        // Sample data to pass to DashboardChart
        const [chartData, setChartData] = useState([
            {
                label: 'CH_1 Flow meter 01',
                data: [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            {
                label: 'CH_3 Flow meter 02',
                data: [],
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
            },
            {
                label: 'CH_5 pH',
                data: [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            {
                label: 'CH_6 Conductivity',
                data: [],
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
            },
            {
                label: 'CH_7 Salinity',
                data: [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            {
                label: 'CH_8 Turbidity',
                data: [],
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
            },
            {
                label: 'CH_9 Chlorine',
                data: [],
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
            {
                label: 'CH_10 Water temp',
                data: [],
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
            }
        ]);

        // Array to store API update times
        const [updateTimes, setUpdateTimes] = useState([]);

        // Function to fetch data from the API and update the state and chartData
  const fetchData = async () => {
    try {
      const response = await fetch('https://iot.aisack.com/dataset/realtime/sahatanachon/nongree');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();

      // Transform the API response into an array with status based on timestamp
      const newData = Object.keys(jsonData).map((key) => {
        const timestamp = new Date(jsonData[key].timestamp).getTime();
        const currentTime = new Date().getTime();
        const timeDiffInSeconds = (currentTime - timestamp) / 1000;

        return {
          name: key,
          value: jsonData[key].value,
          unit: jsonData[key].unit,
          timestamp: new Date(jsonData[key].timestamp).toLocaleString(),
          status: timeDiffInSeconds <= 120 ? 'green' : 'red', // Check if timestamp is within 2 minutes
        };
      });

      setData(newData);

      // Update API update times
      const updateTime = new Date().toLocaleTimeString();
      setUpdateTimes((prevUpdateTimes) => [...prevUpdateTimes, updateTime]);

      // Update chartData with new data
      const updatedChartData = chartData.map((dataset) => {
        // Find the corresponding data for this dataset
        const newDataForDataset = newData.find((item) => item.name === dataset.label);
        if (newDataForDataset) {
          // Update the dataset's data with the new value
          dataset.data.push(newDataForDataset.value);
          // Keep only the last 24 data points (1 data point per hour for 24 hours)
          if (dataset.data.length > 24) {
            dataset.data.shift(); // Remove the oldest data point
          }
        }
        return dataset;
      });

      // Set the updated chartData
      setChartData(updatedChartData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data initially when the component mounts
  useEffect(() => {
    fetchData();

    // Set up an interval to fetch data every 30 seconds
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array to run the effect only once


    const [isFullScreen, setIsFullScreen] = useState(false);

    // Function to toggle fullscreen
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    // Function to exit fullscreen when "Escape" key is pressed
    const exitFullScreen = (event) => {
        if (event.key === 'Escape' && isFullScreen) {
            toggleFullScreen();
        }
    };

    useEffect(() => {
        // Add event listener for "keydown" event on the document
        document.addEventListener('keydown', exitFullScreen);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', exitFullScreen);
        };
    }, [isFullScreen]);

    const dashboardPanelClass = `dashboard-panel ${isFullScreen ? 'fullscreen' : ''}`;

    // Define the maximum height for scrollable content
    const maxScrollableHeight = isFullScreen ? '100vh' : '65vh';

    return (
        <MainLayout>
            <div className='px-5 py-2'>
                <div className="flex px-3 py-2 justify-between">
                    <div className="font-bold text-xl">Dashboard</div>
                    <div>
                        <button onClick={toggleFullScreen}>
                            {isFullScreen ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                    />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>

                {/* DASHBOARD PANEL FULL SCREEN WHEN CLICK BUTTON */}
                <div className={dashboardPanelClass}>
                <div class="bg-slate-200 py-3 flex flex-col items-center sm:flex-row sm:justify-between">
  <div class="mt-2 sm:mt-0">
    <img
      src="/dashboard/pwa.png" // Replace with the actual path to your logo
      alt="Menu"
      className='dashboard-logo mx-10'
    />
  </div>
  <div class="text-center font-bold text-base mt-2 sm:mt-0 sm:text-2xl">
    <div>ระบบตรวจสอบคุณภาพน้ำประปา</div>
    <div>การประปาส่วนภูมิภาค สาขาบ้านบึง</div>
    <div>สถานีจ่ายน้ำหนองรี</div>
  </div>
  <div class="mt-2 sm:mt-0">
    <img
      src="/dashboard/saha.png" // Replace with the actual path to your logo
      alt="Menu"
      className='dashboard-logo mx-10'
    />
  </div>
</div>

                    
                    {/* Section with many contents wrapped in a scrollable div */}
                    <div className='scrollable-content' style={{ maxHeight: maxScrollableHeight, overflowY: 'auto' }}>
                        <div className='justify-center pb-5 flex flex-col items-center sm:flex-row sm:justify-between'>
                            <div>

                                <div className='img-widget'>
                                    <img
                                        src="/dashboard/WQ.jpg" // Replace with the actual path to your logo
                                        alt="Menu"
                                        className='p-5 custom-img'
                                    />
                                    <div class="widget-1 text-white font-bold">
                                        <div>ค่ากรด-ด่าง</div>
                                        <div>pH Value</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_5 pH")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>pH</div>
                                        </div>
                                    </div>
                                    <div class="widget-2 text-white font-bold">
                                        <div>การนำไฟฟ้า</div>
                                        <div>Conductivity</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_6 Conductivity")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>µS/cm</div>
                                        </div>
                                    </div>
                                    <div class="widget-3 text-white font-bold">
                                        <div>ค่าความขุ่น</div>
                                        <div>Turbidity</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_8 Turbidity")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>NTU</div>
                                        </div>
                                    </div>
                                    <div class="widget-4 text-white font-bold">
                                        <div>ค่าคลอรีน</div>
                                        <div>Chlorine</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_9 Chlorine")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>ppm</div>
                                        </div>
                                    </div>
                                    <div class="widget-5 text-white font-bold">
                                        <div>ค่าความเค็ม</div>
                                        <div>Salinity</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_7 Salinity")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>mg/l</div>
                                        </div>
                                    </div>
                                    <div class="widget-6 text-white font-bold">
                                        <div>ค่าอุณหภูมิน้ำ</div>
                                        <div>Water Temp</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_10 Water temp")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>°C</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-slate-200 rounded dashboard-chart px-10'>
                                    {/* I want to add chart */}
                                    <DashboardChart chartData={chartData} updateTimes={updateTimes} ></DashboardChart>
                                </div>
                            </div>
                            <div>
                                <div className='img-widget'>
                                    <img
                                        src="/dashboard/FL.jpg" // Replace with the actual path to your logo
                                        alt="Menu"
                                        className='w-full p-5 custom-img'
                                    />
                                    <div class="widget-10 text-white font-bold">
                                        <div className='widget-value'>Flow Meter 01</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_1 Flow meter 01")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>m3/h</div>
                                        </div>
                                        <div className='widget-value flex'>
                                            <div className='mr-1'>∑</div>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_2 Tot Flow meter 01")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>m3</div>
                                        </div>
                                    </div>
                                    <div class="widget-11 text-white font-bold">
                                        <div className='widget-value'>Flow Meter 02</div>
                                        <div className='widget-value flex'>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_3 Flow meter 02")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>m3/h</div>
                                        </div>
                                        <div className='widget-value flex'>
                                            <div className='mr-1'>∑</div>
                                            {data.length > 0 ? (
                                                data.find(item => item.name === "CH_4 Tot Flow meter 02")?.value ?? 'Loading...'
                                                ) : (
                                                'Loading...'
                                            )}
                                            <div className='ml-1'>m3</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='rounded dashboard-chart'>
                                    <DashboardTable data={data}></DashboardTable>
                                </div>
                            </div>
                            {/* Add more content here */}
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default Dashboard;
