import React, { useState } from 'react';
import AlarmTable from '../components/AlarmTable';
import MainLayout from '../layouts/MainLayout';

const Alarm = () => {
    // Sample data for the table
    const data = [
        // { status: 'green', name: 'Item 1', value: 42, unit: 'kg', timestamp: '2023/09/07 12:30:45' },
        // { status: 'red', name: 'Item 2', value: 56, unit: 'm', timestamp: '2023/09/07 13:15:20' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 1', value: 42, unit: 'kg', timestamp: '2023/09/07 12:30:45' },
        // { status: 'red', name: 'Item 2', value: 56, unit: 'm', timestamp: '2023/09/07 13:15:20' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 1', value: 42, unit: 'kg', timestamp: '2023/09/07 12:30:45' },
        // { status: 'red', name: 'Item 2', value: 56, unit: 'm', timestamp: '2023/09/07 13:15:20' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 1', value: 42, unit: 'kg', timestamp: '2023/09/07 12:30:45' },
        // { status: 'red', name: 'Item 2', value: 56, unit: 'm', timestamp: '2023/09/07 13:15:20' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 1', value: 42, unit: 'kg', timestamp: '2023/09/07 12:30:45' },
        // { status: 'red', name: 'Item 2', value: 56, unit: 'm', timestamp: '2023/09/07 13:15:20' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 1', value: 42, unit: 'kg', timestamp: '2023/09/07 12:30:45' },
        // { status: 'red', name: 'Item 2', value: 56, unit: 'm', timestamp: '2023/09/07 13:15:20' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
        // { status: 'green', name: 'Item 3', value: 18, unit: '°C', timestamp: '2023/09/07 14:05:10' },
    ];

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
  
    const handleStartDateChange = (e) => {
      setStartDate(e.target.value);
    };
  
    const handleEndDateChange = (e) => {
      setEndDate(e.target.value);
    };
  
    const handleSearch = () => {
      // Implement your search logic here based on startDate, endDate, and selectedTags
    };

    return (
        <MainLayout>
            <div className='px-5 py-2'>
                <div className='flex justify-between px-3 py-2'>
                    <div className='font-bold text-xl'>Alarms</div>
                    <div className="flex">
                <div className="flex items-center m-2">
                  <label htmlFor="startDate" className="mr-2">
                    Start Date & Time:
                  </label>
                  <input
                    type="datetime-local"
                    id="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div className="flex items-center m-2">
                  <label htmlFor="endDate" className="mr-2">
                    End Date & Time:
                  </label>
                  <input
                    type="datetime-local"
                    id="endDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
                <button
                  className="bg-blue-500 text-white mx-5 px-4 rounded"
                  onClick={handleSearch}
                >
                  Search
                </button>
                
                {/* Add Export to XLS Button */}
                <button
                  className="bg-green-500 text-white px-4 rounded ml-2"
                //   onClick={handleExportToXLS}
                >
                  Export to XLS
                </button>
              </div>
                </div>
                <AlarmTable data={data}></AlarmTable>
            </div>
        </MainLayout>
    );
};

export default Alarm;
