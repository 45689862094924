import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import SiteMenu from '../components/SiteMenu';
import UserBtn from '../components/UserBtn';
import SiteBarItem from '../components/SiteBarItem';


function MainLayout({ children }) {

  const isDesktop = () => window.innerWidth > 1024;

  const [isSidebarVisible, setSidebarVisible] = useState(isDesktop());

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

    // Use useEffect to adjust the right panel's width when the sidebar visibility changes
    useEffect(() => {
      const rightPanel = document.querySelector('.right-panel');
      if (rightPanel) {
        rightPanel.style.width = isSidebarVisible ? 'calc(100% - 300px)' : '100%';
      }
    }, [isSidebarVisible]);

  return (
    <div>

{/* Top Nav Bar */}
<div className='navBar flex px-3 py-2 justify-between'>
  <div className='flex items-center'> {/* Align items to the start */}
    {/* I want to toggle sideBar to show/hide */}
    <img
      src="/menu.png" // Replace with the actual path to your logo
      alt="Menu"
      className='navBar-menu'
      onClick={toggleSidebar}
    />
    <div className='font-bold text-xl text-white mx-3'>IoT Platform</div>
  </div>

  <div> {/* Align items to the end */}
    <UserBtn></UserBtn>
  </div>
</div>
      <div className='flex'>
        {/* Side Bar */}
        <div
          className={`sideBar bg-white py-10 px-5 ${
            isSidebarVisible ? '' : 'hidden'
          }`}
        >
        <img
            src="/logo.png" // Replace with the actual path to your logo
            alt="Logo"
            className="w-10/12 mx-auto mb-4" // Adjust styling as needed
        />

        {/* Menu */}
        <ul className="menu">
          <li className='py-1'><SiteMenu sites={['สถานีจ่ายน้ำหนองรี']} /></li>

          {/* Horizontal Line */}
          <hr className="mt-6 border-t border-gray-300" />

          <li className='pb-2 pt-5'>
            <a href="/dashboard" className="menu-item"><SiteBarItem name='Dashboard' img='/dashboard-black.png'></SiteBarItem></a>
          </li>
          <li className='py-2'>
            <a href="/realtime" className="menu-item"><SiteBarItem name='Realtime' img='/realtime.png'></SiteBarItem></a>
          </li>
          <li className='py-2'>
            <a href="/historical" className="menu-item"><SiteBarItem name='Historical' img='/historical.png'></SiteBarItem></a>
          </li>
          <li className='py-2'>
            <a href="/alarm" className="menu-item"><SiteBarItem name='Alarm'  img='/alarm.png'></SiteBarItem></a>
          </li>
        </ul>
      </div>

      {/* Right Panel */}
      <div className='right-panel p-2 md:p-5'>
        {/* Content */}
        <div className='page-content'>
          <div className='h-full bg-white rounded-xl'>
            <main>{children}</main>
          </div>
        </div>

      </div>
      </div>

    </div>
  );
}

export default MainLayout;
