import { useState, useEffect } from 'react';

export default function RealtimeTable({ data }) {
  const [tableWidth, setTableWidth] = useState('100%');

  useEffect(() => {
    // Function to handle window resize and update table width
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // For mobile devices, set a fixed width of 1000px
        setTableWidth('1000px');
      } else {
        // For desktop, set width to 100%
        setTableWidth('100%');
      }
    };

    // Initial call to set the initial width
    handleResize();

    // Add an event listener to update width on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='overflow-x-auto mt-3' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
      <table style={{ width: tableWidth }}>
        <thead className='rounded-full bg-slate-200' style={{ position: 'sticky', top: '0' }}>
          <tr>
            <th className='text-center py-2' style={{ width: '50px' }}>Status</th>
            <th className='py-2' style={{ width: '100px' }}>Name</th>
            <th className='text-center py-2' style={{ width: '100px' }}>Value</th>
            <th className='text-center py-2' style={{ width: '100px' }}>Unit</th>
            <th className='text-center py-2' style={{ width: '100px' }}>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className='border-b border-gray-300'>
              <td className='text-center py-3 border' style={{ width: '50px' }}>
                <div className='flex justify-center items-center'>
                  <div
                    className={`w-4 h-4 rounded-full ${item.status === 'green' ? 'bg-green-500' : 'bg-red-500'}`}
                  ></div>
                </div>
              </td>
              <td className='px-3 border' style={{ width: '100px' }}>{item.name}</td>
              <td className='px-3 text-center border' style={{ width: '100px' }}>{item.value}</td>
              <td className='px-3 text-center border' style={{ width: '100px' }}>{item.unit}</td>
              <td className='px-3 text-center border' style={{ width: '100px' }}>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
