import React, { useState } from 'react';
import AuthLayout from '../layouts/AuthLayout';
import InputText from '../components/InputText';
import { login } from '../services/api';
import Cookies from 'js-cookie';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);

  const handleLogin = async () => {
    try {
      setLoginError(null); // Clear any previous login error

      const response = await login(email, password);
      // Handle the response, e.g., store tokens or redirect to the dashboard
      const token = response.token;
      Cookies.set('token', token, { expires: 7 });
      // Redirect to the dashboard (modify the path as needed)
      window.location.href = '/';
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setLoginError('Invalid email or password. Please try again.'); // Set the login error message
      } else {
        console.error('Login failed:', error);
        setLoginError('An error occurred during login. Please try again.');
      }
    }
  };

  return (
    <AuthLayout>
      <div className='my-10 flex justify-center'>
        <h1 className='font-bold text-xl'>IoT Platform</h1>
      </div>

      <div className='my-5'>
        <InputText
          type='email'
          placeholder='Username'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className='my-5'>
        <InputText
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      {loginError && (
        <div className='text-red-600 text-center my-2'>{loginError}</div>
      )}

      <div className='mt-10 mb-10'>
        <button
          onClick={handleLogin}
          className='bg-black rounded text-white font-bold px-10 py-2 w-full'
        >
          Login
        </button>
      </div>
    </AuthLayout>
  );
};

export default Login;
