import AuthLayout from '../layouts/AuthLayout';

const Password = () => {
    return (
        <AuthLayout>
            <h1>Password</h1>
        </AuthLayout>
    )
};
  
export default Password;