export default function HistoricalTable({ data }) {
  // Check if there is any data
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  // Get the column names from the first row of data
  const columns = Object.keys(data[0]);

  return (
    <div className='overflow-x-auto mt-3' style={{ maxHeight: '220px', overflowY: 'auto' }}>
      <table className='w-full'>
        <thead className='rounded-full bg-slate-200' style={{ position: 'sticky', top: '0' }}>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className='text-center py-2 border'>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className='border-b border-gray-300'>
              {columns.map((column, columnIndex) => (
                <td key={columnIndex} className='text-center py-3 border'>
                  {item[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
