import React, { useState } from 'react';
import ChangePassword from './ChangePassword';
import LogoutConfirmation from './LogoutConfirmation';


export default function UserBtn(props) {

    const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // Toggle dropdown visibility on button click
    };

    const toggleChangePassword = () => {
        setShowChangePassword(!showChangePassword); // Toggle the change password form visibility
    };

    const toggleLogoutConfirmation = () => {
        setShowLogoutConfirmation(!showLogoutConfirmation); // Toggle the logout confirmation popup
    };

    const handleLogout = () => {
        toggleLogoutConfirmation();
    };
    
    return (
        <div className="relative inline-block text-left">
          <button
            onClick={toggleDropdown}
            className="text-white font-bold px-4 py-2 rounded-full flex items-center hover:bg-black"
          >
            <img
              src="/profile-user.png" // Replace with the actual path to your user icon
              alt="User"
              className="w-6 h-6 mr-2" // Adjust the width, height, and margin as needed
            />
            sahatanachon
          </button>
          {isOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
              <ul>
                {/* <li>
                  <button
                    onClick={toggleChangePassword}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full"
                  >
                    Change Password
                  </button>
                </li> */}
                <li>
                  <button
                    onClick={handleLogout}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
          {showChangePassword && (
            <ChangePassword onClose={toggleChangePassword} />
           )}
           {showLogoutConfirmation && (
        <LogoutConfirmation
          onConfirm={() => {
            // Add logic to clear cookies and local storage here
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            localStorage.clear();
            window.location.href = '/';

            // Example: document.cookie = 'cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            // Example: localStorage.removeItem('localStorageItemName');
            toggleLogoutConfirmation(); // Close the confirmation popup

          }}
          onCancel={toggleLogoutConfirmation} // Close the confirmation popup
        />
      )}
        </div>
      );
}